import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCmpAWL43xLvSfI11gL2XhIXN5lUAr6jsU",
    authDomain: "ecommerce-bc76c.firebaseapp.com",
    projectId: "ecommerce-bc76c",
    storageBucket: "ecommerce-bc76c.appspot.com",
    messagingSenderId: "657993007922",
    appId: "1:657993007922:web:01d019b2c06fa81b0ecd2b",
    measurementId: "G-9P5J2WNHR7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {db, auth, storage};
