import Header from './Components/Header/Header'; //Include Header
import Footer from './Components/Footer/Footer'; //Include Footer
import Home from './Pages/Home/Home'
import Shop from './Pages/Shop/Shop'
import Men from "./Pages/Men/Men";
import SingleProduct from './Components/SingleProduct/SingleProduct'
import Checkout from './Pages/Checkout/Checkout'
import Cart from './Pages/Cart/Cart'
import Login from './Pages/Login/Login'
import Signup from './Pages/SignUp/Signup'
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword'
import {
    HashRouter,
    Routes,
    Route,
} from "react-router-dom";
import Women from "./Pages/Women/Women";
import ShopByCategory from "./Components/ShopByCategory/shopByCategory";

function App() {
    return (
        <div className="App">

            <HashRouter>
                <Header></Header>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/shop" element={<Shop/>}/>
                    <Route path="/men" element={<Men/>}/>
                    <Route path="/women" element={<Women/>}/>
                    <Route exact path="/:gender/:category" element={<ShopByCategory/>}/>
                    <Route exact path="/Products/:itemId" element={<SingleProduct/>}/>
                    <Route path="/checkout" element={<Checkout/>}/>
                    <Route path="/cart" element={<Cart/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                </Routes>
                <Footer></Footer>
            </HashRouter>

        </div>
    );
}

export default App;
