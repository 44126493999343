import Cat1 from './img/cat-1.jpg';
import Cat2 from './img/cat-2.jpg';
import Cat3 from './img/cat-3.jpg';
import Frock from './img/322.jpg';
import Blouse from './img/444.jpg';
import Shirt from './img/222.jpg';
import DenimFrock from './img/111.jpg';
import adsv from './img/adsv.jpg';
import p1 from '../Shop/img/p-1.jpg';
import p2 from '../Shop/img/p-2.jpg';
import p3 from '../Shop/img/p-3.jpg';
import p4 from './img/p-4.jpg';
import p5 from './img/p-5.jpg';
import p7 from './img/p-7.jpg';
import p8 from './img/p-8.jpg';
import {useNavigate} from "react-router-dom";


function Home() {
    const navigate = useNavigate();
    const items = [
        {
            image: Frock,
            name: 'Floral Kirby',
            oldPrice: '0',
            price: '$329.10',
            isSale: true,
        },
        {
            image: DenimFrock,
            name: 'Open Knit Sweater',
            oldPrice: '0',
            price: '$29.10',
            isSale: false,
        },
        {
            image: Shirt,
            name: 'Official Trendy',
            oldPrice: '0',
            price: '$350.00 – $355.00',
            isSale: true,
        },
        {
            image: Frock,
            name: 'Short Frock',
            oldPrice: '0',
            price: '$249',
            isSale: false,
        },
        {
            image: Blouse,
            name: 'Sleeve Dress',
            oldPrice: '0',
            price: '$59.10',
            isSale: false,
        },
        {
            image: Frock,
            name: 'Stylish Dress',
            oldPrice: '0',
            price: '$99.00',
            isSale: false,
        },
        {
            image: DenimFrock,
            name: 'Body Suite',
            oldPrice: '0',
            price: '$329.10',
            isSale: false,
        },
        {
            image: Shirt,
            name: 'Sleeve Linen Shirt',
            oldPrice: '60',
            price: '$50.10',
            isSale: false,
        },

    ];
    const sellerItems = [
        {
            image: p1,
            name: 'Keds - Kickstart Pom Pom',
            price: '$42.99',
        },
        {
            image: p2,
            name: 'Nike - Brasilia Medium Backpack',
            price: '$27.99',
        },
        {
            image: p3,
            name: 'Guess - GU7295',
            price: '$38.00',
        },
        {
            image: p4,
            name: 'Adidas Originals Cap',
            price: '$35.00',
        },
        {
            image: p5,
            name: 'Big Star Flip Tops',
            price: '$10.60',
        },
    ];
    const newItems = [
        {
            image: p7,
            name: 'Keds - Kickstart Pom Pom',
            price: '$42.99',
        },
        {
            image: p8,
            name: 'Nike - Brasilia Medium Backpack',
            price: '$27.99',
        },
        {
            image: p1,
            name: 'Guess - GU7295',
            price: '$38.00',
        },
        {
            image: p2,
            name: 'Adidas Originals Cap',
            price: '$35.00',
        },
        {
            image: p4,
            name: 'Big Star Flip Tops',
            price: '$10.60',
        },
    ];
    return (
        <div className="home-container">
            <div className="main-slider slider slick-initialized slick-slider">
                <div className="slider-item" style={{
                    backgroundImage: "url('assets/images/slideshow1-2.jpg')",
                    backgroundPosition: "50%",
                    backgroundRepeat: "no-repeat"
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 offset-lg-6 offset-md-6">
                                <div className="slider-caption">
                                    <span className="lead">Trendy dress</span>
                                    <h1 className="mt-2 mb-5"><span className="text-color">Winter </span>Collection</h1>
                                    <button className="btn btn-main"
                                            onClick={() => {
                                                window.scrollTo(0, 0);
                                                navigate('/shop');
                                            }}
                                    >Shop Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="category section pt-3 pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12 col-md-6">
                            <div className="cat-item mb-4 mb-lg-0">
                                <img src={Cat1} alt="" className="img-fluid"/>
                                <div className="item-info">
                                    <p className="mb-0">Stylish Leather watch</p>
                                    <h4 className="mb-4">up to <strong>50% </strong>off</h4>
                                    <a href="/#/shop" className="read-more" onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}>Shop now</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12 col-md-6">
                            <div className="cat-item mb-4 mb-lg-0">
                                <img src={Cat2} alt="" className="img-fluid"/>

                                <div className="item-info">
                                    <p className="mb-0">Ladies hand bag</p>
                                    <h4 className="mb-4">up to <strong>40% </strong>off</h4>

                                    <a href="/#/shop" className="read-more" onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}>Shop now</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12 col-md-6">
                            <div className="cat-item">
                                <img src={Cat3} alt="" className="img-fluid"/>
                                <div className="item-info">
                                    <p className="mb-0">Trendy shoe</p>
                                    <h4 className="mb-4">up to <strong>50% </strong>off</h4>

                                    <a href="/#/shop" className="read-more" onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}>Shop now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section products-main">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="title text-center">
                                <h2>New arrivals</h2>
                                <p>The best Online sales to shop this weekend</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {items.map((item) => (
                            <div key={item.name} className="col-lg-3 col-12 col-md-6 col-sm-6 mb-5">
                                <div className="product">
                                    <div className="product-wrap">
                                        <a href="/#/single-product" onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}><img className="img-fluid w-100 mb-3 img-first" src={item.image}
                                                alt="product-img"/></a>
                                    </div>
                                    {item.isSale && <span className="onsale">Sale</span>}
                                    <div className="product-hover-overlay">
                                        <a href="/single-product"><i className="tf-ion-android-cart"></i></a>
                                        <a href="/single-product"><i className="tf-ion-ios-heart"></i></a>
                                    </div>

                                    <div className="product-info">
                                        <h2 className="product-title h5 mb-0">
                                            <a href="/#/single-product"
                                               onClick={() => {
                                                   window.scrollTo(0, 0);
                                               }}>{item.name}</a>
                                        </h2>
                                        <span className="price">
                                            {item.oldPrice !== '0' && <del>{item.oldPrice}
                                                <pre wp-pre-tag-3=""></pre>
                                            </del>}
                                            {item.price}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <section className="ads section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 offset-lg-6">
                            <div className="ads-content">
                                <span className="h5 deal">Deal of the day 50% Off</span>
                                <h2 className="mt-3 text-white">Trendy Suit</h2>
                                <p className="text-md mt-3 text-white">Hurry up! Limited time offer.Grab now!</p>

                                <div id="simple-timer" className="syotimer mb-5"></div>
                                <a href="/#/shop" className="btn btn-main" onClick={() => {
                                    window.scrollTo(0, 0);
                                }}><i className="ti-bag mr-2"></i>Shop Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section products-list">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12 col-md-12">
                            <img src={adsv} alt="Product big thumb" className="img-fluid w-100"/>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="widget-featured-entries mt-5 mt-lg-0">
                                <h4 className="mb-4 pb-3">Best sellers</h4>
                                {sellerItems.map((item) => (
                                    <div key={item.name} className="media mb-3">
                                        <a className="featured-entry-thumb" href="/#/single-product" onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}>
                                            <img src={item.image} alt="Product thumb" width="64"
                                                 className="img-fluid mr-3"/>
                                        </a>
                                        <div className="media-body">
                                            <h6 className="featured-entry-title mb-0">
                                                <a href="/#/single-product"
                                                   onClick={() => {
                                                       window.scrollTo(0, 0);
                                                   }}>{item.name}</a></h6>
                                            <p className="featured-entry-meta">{item.price}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>


                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="widget-featured-entries mt-5 mt-lg-0">
                                <h4 className="mb-4 pb-3">New Arrivals</h4>

                                {newItems.map((item) => (
                                    <div key={item.name} className="media mb-3">
                                        <a className="featured-entry-thumb" href="/#/single-product" onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}>
                                            <img src={item.image} alt="Product thumb" width="64"
                                                 className="img-fluid mr-3"/>
                                        </a>
                                        <div className="media-body">
                                            <h6 className="featured-entry-title mb-0">
                                                <a href="/#/single-product"
                                                   onClick={() => {
                                                       window.scrollTo(0, 0);
                                                   }}>{item.name}</a></h6>
                                            <p className="featured-entry-meta">{item.price}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="features border-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="feature-block">
                                <i className="tf-ion-android-bicycle"></i>
                                <div className="content">
                                    <h5>Free Shipping</h5>
                                    <p>On all order over $39.00</p>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="feature-block">
                                <i className="tf-wallet"></i>
                                <div className="content">
                                    <h5>30 Days Return</h5>
                                    <p>Money back Guarantee</p>
                                </div>
                            </div>
                        </div>*/}
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="feature-block">
                                <i className="tf-key"></i>
                                <div className="content">
                                    <h5>Secure Checkout</h5>
                                    <p>100% Protected by paypal</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="feature-block">
                                <i className="tf-clock"></i>
                                <div className="content">
                                    <h5>24/7 Support</h5>
                                    <p>All time customer support </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home;
