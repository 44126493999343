import dress1 from './img/dress5.jpg';
import top1 from './img/top5.jpg';
import bottom1 from './img/bottom6.jpg';
import {useNavigate} from "react-router-dom";
import './women.css';

function Women() {
    const navigate = useNavigate();
    return (
        <div className="shop-container">
            <section className="page-header">
                <div className="overly"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="content text-center">
                                <h1 className="mb-3">Women</h1>
                                <p>Hath after appear tree great fruitful green dominion moveth sixth abundantly image
                                    that midst of god day multiply you’ll which</p>

                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-transparent justify-content-center">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Women</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="main-banner" id="top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Dress</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/women/Dresses`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>Dress</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/women/Dresses'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={dress1} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Bottom</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/women/Bottoms`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>Bottom</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/women/Bottoms'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={bottom1} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Top</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/women/Tops`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>Top</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/women/Tops'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={top1} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Women;
