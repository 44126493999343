import Frock from './img/322.jpg';
import Blouse from './img/444.jpg';
import Shirt from './img/222.jpg';
import DenimFrock from './img/111.jpg';
import p1 from '../Shop/img/p-1.jpg';
import p2 from '../Shop/img/p-2.jpg';
import p3 from '../Shop/img/p-3.jpg';

function Shop() {
    const items = [
        {
            image: Frock,
            name: 'Floral Kirby',
            price: '$329.10',
            isSale: true,
        },
        {
            image: DenimFrock,
            name: 'Open knit switer',
            price: '$29.10',
            isSale: false,
        },
        {
            image: Shirt,
            name: 'Official trendy',
            price: '$350.00 – $355.00',
            isSale: true,
        },
        {
            image: Frock,
            name: 'Frock short',
            price: '$249',
            isSale: false,
        },
        {
            image: Blouse,
            name: 'Sleeve dress',
            price: '$59.10',
            isSale: false,
        },
        {
            image: Frock,
            name: 'Stylish dress',
            price: '$99.00',
            isSale: false,
        },
    ];
    const popularItems = [
        {
            image: p1,
            name: 'Contrast Backpack',
            price: '$45',
        },
        {
            image: p2,
            name: 'Hoodie with Logo',
            price: '$45',
        },
        {
            image: p3,
            name: 'Traveller Backpack',
            price: '$45',
        },

    ];
    return (
        <div className="shop-container">
            <section className="page-header">
                <div className="overly"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="content text-center">
                                <h1 className="mb-3">Shop</h1>
                                <p>Hath after appear tree great fruitful green dominion moveth sixth abundantly image
                                    that midst of god day multiply you’ll which</p>

                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-transparent justify-content-center">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Shop</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="products-shop section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row align-items-center">
                                <div className="col-lg-12 mb-4 mb-lg-0">
                                    <div className="section-title">
                                        <h2 className="d-block text-left-sm">Shop</h2>

                                        <div className="heading d-flex justify-content-between mb-5">
                                            <p className="result-count mb-0"> Showing 1–6 of 17 results</p>
                                            <form className="ordering " method="get">
                                                <select name="orderby" className="orderby form-control"
                                                        aria-label="Shop order">
                                                    <option defaultValue value="">Default sorting</option>
                                                    <option value="">Sort by popularity</option>
                                                    <option value="">Sort by average rating</option>
                                                    <option value="">Sort by latest</option>
                                                    <option value="">Sort by price: low to high</option>
                                                    <option value="">Sort by price: high to low</option>
                                                </select>
                                                <input type="hidden" name="paged" value="1"/>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {items.map((item) => (
                                    <div key={item.name} className="col-lg-4 col-12 col-md-6 col-sm-6 mb-5">
                                        <div className="product">
                                            <div className="product-wrap">
                                                <a href="#/single-product" onClick={() => {
                                                    window.scrollTo(0, 0);
                                                }}><img className="img-fluid w-100 mb-3 img-first"
                                                        src={item.image} alt="product-img"/></a>
                                            </div>
                                            {item.isSale && <span className="onsale">Sale</span>}
                                            <div className="product-hover-overlay">
                                                <a href="src/Pages/Shop/Shop#"><i
                                                    className="tf-ion-android-cart"></i></a>
                                                <a href="src/Pages/Shop/Shop#"><i className="tf-ion-ios-heart"></i></a>
                                            </div>

                                            <div className="product-info">
                                                <h2 className="product-title h5 mb-0">
                                                    <a href="#/single-product"
                                                       onClick={() => {
                                                           window.scrollTo(0, 0);
                                                       }}>{item.name}</a>
                                                </h2>
                                                <span className="price">{item.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="col-12">
                                    <nav aria-label="Page navigation">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="src/Pages/Shop/Shop#"
                                                   aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li className="page-item active"><a className="page-link"
                                                                                href="src/Pages/Shop/Shop#">1</a></li>
                                            <li className="page-item"><a className="page-link"
                                                                         href="src/Pages/Shop/Shop#">2</a>
                                            </li>
                                            <li className="page-item"><a className="page-link"
                                                                         href="src/Pages/Shop/Shop#">3</a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link" href="src/Pages/Shop/Shop#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <form className="mb-5">
                                <section className="widget widget-colors mb-5">
                                    <h3 className="widget-title h4 mb-4">Shop by color</h3>
                                    <ul className="list-inline">
                                        <li className="list-inline-item mr-4">
                                            <div className="custom-control custom-checkbox color-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="color1"/>
                                                <label className="custom-control-label sky-blue"
                                                       htmlFor="color1"></label>
                                            </div>
                                        </li>
                                        <li className="list-inline-item mr-4">
                                            <div className="custom-control custom-checkbox color-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="color2"
                                                       defaultChecked/>
                                                <label className="custom-control-label red" htmlFor="color2"></label>
                                            </div>
                                        </li>
                                        <li className="list-inline-item mr-4">
                                            <div className="custom-control custom-checkbox color-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="color3"/>
                                                <label className="custom-control-label dark" htmlFor="color3"></label>
                                            </div>
                                        </li>
                                        <li className="list-inline-item mr-4">
                                            <div className="custom-control custom-checkbox color-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="color4"/>
                                                <label className="custom-control-label magenta"
                                                       htmlFor="color4"></label>
                                            </div>
                                        </li>
                                        <li className="list-inline-item mr-4">
                                            <div className="custom-control custom-checkbox color-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="color5"/>
                                                <label className="custom-control-label yellow" htmlFor="color5"></label>
                                            </div>
                                        </li>
                                    </ul>
                                </section>
                                <section className="widget widget-sizes mb-5">
                                    <h3 className="widget-title h4 mb-4">Shop by Sizes</h3>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="size1"
                                               defaultChecked/>
                                        <label className="custom-control-label" htmlFor="size1">L Large</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="size2"/>
                                        <label className="custom-control-label" htmlFor="size2">XL Extra Large</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="size3"/>
                                        <label className="custom-control-label" htmlFor="size3">M Medium</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="size4"/>
                                        <label className="custom-control-label" htmlFor="size4">S Small</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="size5"/>
                                        <label className="custom-control-label" htmlFor="size5">XS Extra Small</label>
                                    </div>
                                </section>
                                <button type="button" className="btn btn-black btn-small">Filter</button>
                            </form>

                            <section className="widget widget-popular mb-5">
                                <h3 className="widget-title mb-4 h4">Popular Products</h3>
                                {popularItems.map((item) => (
                                    <a key={item.name} className="popular-products-item media" href="#/single-product"
                                       onClick={() => {
                                           window.scrollTo(0, 0);
                                       }}>
                                        <img src={item.image} alt="" className="img-fluid mr-4"/>
                                        <div className="media-body">
                                            <h6>{item.name}</h6>
                                            <span className="price">{item.price}</span>
                                        </div>
                                    </a>
                                ))}
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Shop;
