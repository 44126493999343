import short1 from './img/short1.jpg';
import shirt4 from './img/shirt4.jpg';
import pant5 from './img/pant5.jpg';
import shoes from '../Men/img/shoes.jpg';
import tshirt2 from './img/tshirt2.jpg';
import accessories from '../Men/img/Accessories.jpg';
import './men.css';
import {useNavigate} from "react-router-dom";

/*import short2 from '../Men/img/short2.jpg';
import short3 from '../Men/img/short3.jpg';
import short4 from '../Men/img/short4.jpg';
import shirt1 from '../Men/img/shirt1.jpg';
import shirt2 from '../Men/img/shirt2.jpg';
import shirt3 from '../Men/img/shirt3.jpg';
import shirt5 from '../Men/img/shirt5.jpg';
import shirt6 from '../Men/img/shirt6.jpg';
import shirt7 from '../Men/img/shirt7.jpg';
import shirt8 from '../Men/img/shirt8.jpg';
import shirt9 from '../Men/img/shirt9.jpg';
import shirt10 from '../Men/img/shirt10.jpg';
import shirt11 from '../Men/img/shirt11.jpg';
import shirt12 from '../Men/img/shirt12.jpg';
import shirt13 from '../Men/img/shirt13.jpg';
import shirt14 from '../Men/img/shirt14.jpg';
import shirt15 from '../Men/img/shirt15.jpg';
import shirt16 from '../Men/img/shirt16.jpg';
import shirt17 from '../Men/img/shirt17.jpg';
import shirt18 from '../Men/img/shirt18.jpg';
import shirt19 from '../Men/img/shirt19.jpg';
import shirt20 from '../Men/img/shirt20.jpg';
import shirt21 from '../Men/img/shirt21.jpg';
import shirt22 from '../Men/img/shirt22.jpg';
import shirt23 from '../Men/img/shirt23.jpg';
import shirt1a from '../Men/img/shirt1a.jpg';
import shirt2a from '../Men/img/shirt2a.jpg';
import shirt5a from '../Men/img/shirt5a.jpg';
import shirt8a from '../Men/img/shirt8a.jpg';
import shirt10a from '../Men/img/shirt10a.jpg';
import shirt12a from '../Men/img/shirt12a.jpg';
import shirt14a from '../Men/img/shirt14a.jpg';
import shirt15a from '../Men/img/shirt15a.jpg';
import pant1 from '../Men/img/pant1.jpg';
import pant2 from '../Men/img/pant2.jpg';
import pant3 from '../Men/img/pant3.jpg';
import pant4 from '../Men/img/pant4.jpg';
import pant6 from '../Men/img/pant6.jpg';
import pant7 from '../Men/img/pant7.jpg';
import pant8 from '../Men/img/pant8.jpg';
import pant9 from '../Men/img/pant9.jpg';
import pant10 from '../Men/img/pant10.jpg';
import pant11 from '../Men/img/pant11.jpg';
import pant12 from '../Men/img/pant12.jpg';
import pant13 from '../Men/img/pant13.jpg';
import pant14 from '../Men/img/pant14.jpg';
import pant15 from '../Men/img/pant15.jpg';
import pant16 from '../Men/img/pant16.jpg';
import pant17 from '../Men/img/pant17.jpg';
import pant18 from '../Men/img/pant18.jpg';
import pant19 from '../Men/img/pant19.jpg';
import tshirt1 from '../Men/img/tshirt1.jpg';
import tshirt3 from '../Men/img/tshirt3.jpg';
import tshirt4 from '../Men/img/tshirt4.jpg';
import tshirt5 from '../Men/img/tshirt5.jpg';
import tshirt6 from '../Men/img/tshirt6.jpg';
import tshirt7 from '../Men/img/tshirt7.jpg';
import tshirt8 from '../Men/img/tshirt8.jpg';*/

function Men() {
    const navigate = useNavigate();
    return (
        <div className="shop-container">
            <section className="page-header">
                <div className="overly"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="content text-center">
                                <h1 className="mb-3">Men</h1>
                                <p>Hath after appear tree great fruitful green dominion moveth sixth abundantly image
                                    that midst of god day multiply you’ll which</p>

                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-transparent justify-content-center">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Men</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="main-banner" id="top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/men/Shirts`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>Shirt</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/men/Shirts'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt4} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Pants & Trousers</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/men/Pants`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>Pants & Trousers</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/men/Pants'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={pant5} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shoes</h4>
                                                    <span>Best Shoes For Men</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/men/Shoes`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>Shoes</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/men/Shoes'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shoes} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Accessories</h4>
                                                    <span>Best Accessories For Men</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/men/Accessories`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>Accessories</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/men/Accessories'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={accessories} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shorts</h4>
                                                    <span>Best Trend Shorts</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/men/Shorts`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>Shorts</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/men/Shorts'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={short1} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>T Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content" onClick={() => {
                                                    navigate(`/men/TShirts`);
                                                    window.scrollTo(0, 0);
                                                }}>
                                                    <div className="inner">
                                                        <h4>T Shirt</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href={'#/men/TShirts'}>Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={tshirt2} alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*......................Shirts..................................
            <div className="main-banner" id="top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={shirt1}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt2}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt3}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt4}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt5}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt6}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt7}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt8}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt9}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt10}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt11}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt12}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt13}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt14}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt15}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt16}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt17}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt18}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt19}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt20}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt21}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt22}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt23}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt1a}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt2a}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt5a}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt8a}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt10a}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt12a}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt14a}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={shirt15a}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            ......................T Shirts..................................
            <div className="main-banner" id="top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={tshirt1}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={tshirt2}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={tshirt3}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={tshirt4}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={tshirt5}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={tshirt6}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={tshirt7}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={tshirt8}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            ......................Pants..................................
            <div className="main-banner" id="top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant1}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant2}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant3}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant4}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant5}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant6}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant7}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant8}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant9}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant10}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant11}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant12}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant13}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant14}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant15}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant16}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant17}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant18}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={pant19}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            ......................short..................................
            <div className="main-banner" id="top">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={short1}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={short2}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={short3}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                            <div className="right-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="right-first-image">
                                            <div className="thumb">
                                                <img src={short4}/>
                                                <div className="inner-content">
                                                    <h4>Shirt</h4>
                                                    <span>Best Clothes For Men</span>
                                                </div>
                                                <div className="hover-content">
                                                    <div className="inner">
                                                        <h4>Women</h4>
                                                        <p>Lorem ipsum dolor sit amet, conservisii ctetur adipiscing
                                                            elit incid.</p>
                                                        <div className="main-border-button">
                                                            <a href="#">Discover</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}

        </div>
    )
}

export default Men;
