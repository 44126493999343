import "jquery/dist/jquery.slim.min.js";
import "popper.js/dist/umd/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import './Header.css';
import {Link} from "react-router-dom";
import Logo from './img/logo.png';
import * as React from "react";
import {useEffect} from "react";
import {auth, db} from "../../firebase";
import {collection, getDocs, query, where} from "firebase/firestore";

function Header() {
    const [cart, setCart] = React.useState([]);
    useEffect(() => {
        async function fetchCart() {
            if (auth.currentUser !== null) {
                const docSnap = await getDocs(query(collection(db, "Customers"), where("cusID", "==", auth.currentUser.uid)));
                if (docSnap.docs.length !== 0) {
                    setCart([]);
                    docSnap.forEach((doc) => {
                        setCart(doc.data().cart);
                    });
                } else {
                    setCart([]);
                }
            }
        }

        fetchCart();
    }, []);
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-dark w-100 navigation" id="navbar">
            <div className="container">
                <Link className="navbar-brand font-weight-bold" to={{pathname: "/"}}>
                    <img style={{width: '70px'}} src={Logo} alt={"logo"}/>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar"
                        aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse " id="main-navbar">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: "/"}}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: "/men"}}>Men's</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: "/women"}}>Women's</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: "/shop"}}>Kid's</Link>
                        </li>
                        <li className="nav-item dropdown dropdown-slide">
                            <a className="nav-link dropdown-toggle" href="src/Components/Header/Header#"
                               id="navbarDropdown4" role="button" data-delay="350"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Others
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown4">
                                <li><Link to={{pathname: "/shop"}}>Accessories</Link></li>
                                <li><Link to={{pathname: "/shop"}}>Decorations </Link></li>
                                <li><Link to={{pathname: "/shop"}}>Personal Care</Link></li>
                                <li><Link to={{pathname: "/shop"}}>Animal Care</Link></li>
                            </ul>
                        </li>
                        {/*<li className="nav-item dropdown dropdown-slide">
                            <a className="nav-link dropdown-toggle" href="src/Components/Header/Header#"
                               id="navbarDropdown3" role="button" data-delay="350"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Shop.
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown3">
                                <li><Link to={{pathname: "/shop"}}>Shop</Link></li>
                                <li><Link to={{pathname: "/single-product"}}>Product Details</Link></li>
                                <li><Link to={{pathname: "/checkout"}}>Checkout</Link></li>
                                <li><Link to={{pathname: "/cart"}}>Cart</Link></li>
                            </ul>
                        </li>*/}
                    </ul>
                </div>
                <ul className="top-menu list-inline mb-0 d-none d-lg-block" id="top-menu">
                    <li className="list-inline-item">
                        <a href="src/Components/Header/Header#" className="search_toggle" id="search-icon"><i
                            className="tf-ion-android-search"></i></a>
                    </li>

                    <li className="dropdown cart-nav dropdown-slide list-inline-item">
                        <a href="src/Components/Header/Header#" className="dropdown-toggle cart-icon"
                           data-toggle="dropdown"
                           data-hover="dropdown">
                            <i className="tf-ion-android-cart"></i>
                        </a>
                        <div className="dropdown-menu cart-dropdown">
                            {cart.map((item) => (
                                <div key={item.itemId} className="media">
                                    <a href={`#/Products/${item.itemId}`}>
                                        <img className="media-object img- mr-3" src={item.itemImg} alt="image"/>
                                    </a>
                                    <div className="media-body">
                                        <h6>{item.itemName}</h6>
                                        <div className="cart-price">
                                            <span>{item.qty} x</span>
                                            <span>{item.unitPrice}</span>
                                        </div>
                                    </div>
                                    <a href="src/Components/Header/Header#" className="remove"><i
                                        className="tf-ion-close"></i></a>
                                </div>
                            ))}

                            <div className="cart-summary">
                                <span className="h6">Total</span>
                                <span className="total-price h6">$1799.00</span>

                                <div className="text-center cart-buttons mt-3">
                                    <a href="/#/cart"
                                       className="btn btn-small btn-transparent btn-block">View Cart</a>
                                    <a href="/#/checkout"
                                       className="btn btn-small btn-main btn-block">Checkout</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown nav-item dropdown-slide list-inline-item">
                        <a className="nav-link dropdown-toggle" href="src/Components/Header/Header#"
                           id="navbarDropdown5" role="button" data-delay="350"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="tf-ion-ios-person mr-3"></i>
                        </a>
                        <ul className="dropdown-menu cart-dropdown" aria-labelledby="navbarDropdown5">
                            <li><Link to={{pathname: "/login"}}>Login Page</Link></li>
                            <li><Link to={{pathname: "/signup"}}>SignUp Page</Link></li>
                            <li><Link to={{pathname: "/forgot-password"}}>Forgot Password</Link></li>
                            <li onClick={() => {
                                auth.signOut();
                            }}><Link to={{pathname: "/"}}>Log Out</Link></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Header;
