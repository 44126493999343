import * as React from 'react';
import {
    createUserWithEmailAndPassword,
    updateProfile
} from "firebase/auth";
import {setDoc, doc} from "firebase/firestore";
import {db, auth} from "../../firebase";
import {useNavigate} from "react-router-dom";

function SignUp() {
    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const navigate = useNavigate();
    const handleSignUp = async () => {
        if (password === confirmPassword) {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
                await updateProfile(auth.currentUser, {
                    displayName: name,
                });
                const customer = userCredential.user;

                await setDoc(doc(db, "Customers", customer.uid), {
                    cusID: customer.uid,
                    name: name,
                    email: email,
                    password: password,
                    timestamp: new Date(),
                }).then(() => {
                    navigate("/login", {replace: true});
                });
            } catch (error) {
                alert(error.message);
            }

        } else {
            alert("Password & confirm password are not same");
            setPassword("");
            setConfirmPassword("");
        }
    };
    return (
        <div className="signUp-container">
            <div className="account section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="login-form border p-5">
                                <div className="text-center heading">
                                    <h2 className="mb-2">Sign Up</h2>
                                    <p className="lead">Already have an account? <a href="#/login"> Login now</a></p>
                                </div>

                                {/*<form>*/}
                                <div className="form-group mb-4">
                                    <label>Enter Email Address</label>
                                    <input type="email" className="form-control" value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                           placeholder="Enter Email Address"/>
                                </div>
                                <div className="form-group mb-4">
                                    <label>Enter username</label>
                                    <a className="float-right" href="#/forgot-password">Forget password?</a>
                                    <input type="text" className="form-control" value={name}
                                           onChange={(e) => setName(e.target.value)} placeholder="Enter Username"/>
                                </div>
                                <div className="form-group mb-4">
                                    <label>Enter Password</label>
                                    <input type="password" className="form-control" value={password}
                                           onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password"/>
                                </div>
                                <div className="form-group">
                                    <label>Confirm Password</label>
                                    <input type="password" className="form-control" value={confirmPassword}
                                           onChange={(e) => setConfirmPassword(e.target.value)}
                                           placeholder="Confirm Password"/>
                                </div>

                                <button onClick={handleSignUp} className="btn btn-main mt-3 btn-block">Signup</button>
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp
