import * as React from "react";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../firebase";
import {useNavigate} from "react-router-dom";

function Login() {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const navigate = useNavigate();
    const handleLogin = async () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                navigate(-2);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorCode + errorMessage);
            });
    };
    return (
        <div className="login-container">
            <div className="account section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="login-form border p-5">
                                <div className="text-center heading">
                                    <h2 className="mb-2">Login</h2>
                                    <p className="lead">Don’t have an account? <a href="#/signup">Create a
                                        free account</a></p>
                                </div>

                                {/*<form action="src/Pages/Login/Login#">*/}
                                <div className="form-group mb-4">
                                    <label>Enter Email</label>
                                    <input type="email" className="form-control" value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                           placeholder="Enter Email Address"/>
                                </div>
                                <div className="form-group">
                                    <label>Enter Password</label>
                                    <a className="float-right" href="#/forgot-password">Forget password?</a>
                                    <input type="password" className="form-control" value={password}
                                           onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password"/>
                                </div>

                                <button onClick={handleLogin} className="btn btn-main mt-3 btn-block">Login</button>
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
